import { createActions, handleActions } from 'redux-actions';
import cloneDeep from 'lodash/cloneDeep';
import isMatch from 'lodash/isMatch';
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';

function addOrRemove(arr, val) {
  if (findIndex(arr, o => isMatch(o, val)) === -1) {
    arr.push(val);
  } else {
    remove(arr, item => item === val);
  }
}

const initialState = {
  formStep: 0,
  currentFormData: {
    side: 'one-side',
    isBillingAddressSame: 'yes',
    toCompany: false,
    holder: 'no-holder',
  },
  cart: [],
  lockImages: {
    front: '',
    back: '',
  },
};

export const orderFormActions = createActions({
  FORM_CHANGE: undefined,
  FORM_SUBMIT: undefined,
  SET_FORM_STEP: undefined,
  SET_FORM: undefined,
  SET_LOCK_IMAGE: undefined,
  SET_CART: undefined,
  CLEAR_FORM: undefined,
});

const reducer = handleActions(
  {
    [orderFormActions.formChange]: (state, { payload }) => {
      const newState = cloneDeep(state);

      newState.currentFormData = payload;

      return newState;
    },
    [orderFormActions.setFormStep]: (state, { payload }) => {
      function findPos(obj) {
        let curtop = 0;
        if (obj.offsetParent) {
          do {
            curtop += obj.offsetTop;
          } while (obj = obj.offsetParent);
          return [curtop - 50];
        }
      }

      const $form = document.querySelector('#order-form form');

      window.scroll(0, findPos($form) - 100);

      return {
        ...state,
        formStep: payload,
      };
    },
    [orderFormActions.setCart]: (state, { payload }) => {
      const newState = cloneDeep(state);

      newState.cart = payload;

      return newState;
    },
    [orderFormActions.setLockImage]: (state, { payload }) => ({
      ...state,
      lockImages: {
        ...state.lockImages,
        [payload.side]: payload.src,
      },
    }),
    [orderFormActions.clearForm]: (state) => {
      const newState = cloneDeep(state);
      newState.cart = [];
      newState.currentFormData = {};
      newState.formStep = 0;
      localStorage.removeItem('savedCanvas');

      return newState;
    },
  },
  initialState,
);

export default reducer;
