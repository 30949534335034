import { createActions, handleActions } from 'redux-actions';
import moment from 'moment';

// Lodash actions
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import findKey from 'lodash/findKey';
import includes from 'lodash/includes';
import undoable from 'redux-undo';

// Utils
import swap from 'utils/swap';

const initialState = {
  selectedIndex: -1,
  lastSaved: null,
  items: {
    side: 'front',
    front: {
      isActive: true,
      side: 'front',
      objects: [],
      version: '2.6.0',
    },
    back: {
      side: 'back',
      isActive: false,
      objects: [],
      version: '2.6.0',
    },
  },
};

export const canvasObjectActions = createActions({
  SET_OBJECTS: undefined,
  SET_OBJECT: undefined,
  DELETE_OBJECT: undefined,
  ADD_OBJECT: undefined,
  SEND_TO_BACK: undefined,
  SEND_TO_FRONT: undefined,
  SET_SELECTED: undefined,
  CLEAR_SELECTED: undefined,
  CHANGE_SIDE: undefined,
  SAVE: undefined,
  LOAD_SAVED_DATA: undefined,
  SET_LAST_SAVED: undefined,
});

const reducer = handleActions(
  {
    [canvasObjectActions.changeSide]: (state) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);

      set(newState, 'items.front.isActive', side !== 'front');
      set(newState, 'items.back.isActive', side !== 'back');

      return newState;
    },
    [canvasObjectActions.clearSelected]: (state) => {
      return ({
        ...state,
        items: {
          ...state.items,
          index: -1,
        },
        selectedIndex: -1,
      });
    },
    [canvasObjectActions.setSelected]: (state, { payload }) => {
      const isText = state.items[state.items.side].objects[payload] ? state.items[state.items.side].objects[payload].type === 'textbox' : true;

      if (isText) {
        setTimeout(() => {
          const $textInput = document.getElementById('insertText');
          if ($textInput && document.activeElement !== $textInput) {
            $textInput.select();
            $textInput.focus();
          }
        });
      }

      return {
        ...state,
        items: {
          ...state.items,
          index: payload,
        },
        selectedIndex: payload,
      };
    },
    [canvasObjectActions.setObjects]: (state, { payload }) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);

      set(newState, `items.${side}`, {
        isActive: true,
        ...payload,
        side,
      });

      return newState;
    },
    [canvasObjectActions.setObject]: (state, { payload }) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);

      set(newState, `items.${side}.objects[${payload.index}].${payload.name}`, payload.value);

      return newState;
    },
    [canvasObjectActions.deleteObject]: (state, { payload }) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);

      newState.items[side].objects.splice(payload, 1);

      return newState;
    },
    [canvasObjectActions.addObject]: (state, { payload }) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);
      const { type, data } = payload;

      if (type === 'image') {
        newState.items[side].objects.push({ ...data, type: 'group' });
      }

      if (type === 'textbox') {
        newState.items[side].objects.push({
          ...data, fill: 'rgb(255, 255, 255)', type: 'textbox', fontFamily: 'Roboto',
        });
      }

      return newState;
    },
    [canvasObjectActions.sendToBack]: (state, { payload }) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);

      newState.items[side].objects = swap(newState.items[side].objects, payload, 0);

      return newState;
    },
    [canvasObjectActions.sendToFront]: (state, { payload }) => {
      const side = findKey(state.items, 'isActive');
      const newState = cloneDeep(state);
      const objPos = newState.items[side].objects.length - 1;

      newState.items[side].objects = swap(newState.items[side].objects, payload, objPos);

      return newState;
    },
    [canvasObjectActions.save]: (state, { payload = new Date() }) => {
      const newState = cloneDeep(state);

      localStorage.setItem('savedCanvas', JSON.stringify({ items: state.items, lastSaved: moment(payload).format('h:mm') }));
      newState.lastSaved = moment(payload).format('h:mm');

      return newState;
    },
    [canvasObjectActions.setLastSaved]: (state, { payload }) => {
      const newState = cloneDeep(state);

      newState.lastSaved = payload;

      return newState;
    },
    [canvasObjectActions.loadSavedData]: (state) => {
      const newState = cloneDeep(state);

      const savedData = localStorage.getItem('savedCanvas') ? JSON.parse(localStorage.getItem('savedCanvas')) : null;

      if (savedData) {
        newState.items = savedData.items;
        set(newState, 'items.front.isActive', true);
        set(newState, 'items.back.isActive', false);
      }

      return newState;
    },
  },
  initialState,
);

const NotUndoableActions = [
  'SET_SELECTED',
  'CLEAR_SELECTED',
  'CHANGE_SIDE',
];

export default undoable(reducer, {
  filter: action => !includes(NotUndoableActions, action.type) && action,
});
