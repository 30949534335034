import { createActions, handleActions } from 'redux-actions';

const initialState = {
  modalType: null,
  modalProps: {},
};

export const modalActions = createActions({
  SHOW_MODAL: undefined,
  HIDE_MODAL: undefined,
});

const reducer = handleActions(
  {
    [modalActions.showModal]: (state, { payload }) => ({
      modalType: payload.type,
      modalProps: payload.props,
    }),
    [modalActions.hideModal]: () => initialState,
  },
  initialState,
);

export default reducer;
