import fetch from 'isomorphic-unfetch';
import base64 from 'base-64';

import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { APP_URL, APP_ENV } = publicRuntimeConfig;

export default (requestUrl, options = {}) => fetch(
  requestUrl,
  {
    ...options,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-HTTP-HOST': APP_URL.replace('http://', '').replace('https://', ''),
      ...(
        APP_ENV === 'STAGING'
          ? { Authorization: `Basic ${base64.encode('netpeople:peoplenet')}` }
          : {}
      ),
      ...options.headers,
    },
  },
);
